import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput, NamedLink } from '../../../components';

import css from './SignupForm.module.css';

const KEY_CODE_ENTER = 13;

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        onImageUpload,
        invalid,
        intl,
        onOpenTermsOfService,
        onOpenPrivacyPolicy,
        isPasswordVisible,
        isConfirmPasswordVisible,
        handlePasswordVisibilityToggle,
        handleConfirmPasswordVisibilityToggle,
        isPasswordVisibleFunction,
        isConfirmPasswordVisibleFunction,
        form,
        values,
        isUserNameAlreadyExit,
        errorMessage,
      } = fieldRenderProps;

      // email
      const emailLabel = intl.formatMessage({
        id: 'SignupForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // username
      const userNameLabel = intl.formatMessage({
        id: 'SignupForm.userNameLabel',
      });
      const userNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.userNamePlaceholder',
      });

      const userNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.userNameRequired',
      });
      const userNameRequired = validators.required(userNameRequiredMessage);

      // password
      const passwordLabel = intl.formatMessage({
        id: 'SignupForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'SignupForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );

      const passwordInvalidMessage = intl.formatMessage({
        id: 'SignupForm.passwordValid',
      });
      const passwordValid = validators.passwordFormatValid(passwordInvalidMessage);
      
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength,
        passwordValid
      );

      //confirm password
      const confirmPasswordLabel = intl.formatMessage({
        id: 'SignupForm.confirmPasswordLabel',
      });

      // firstName
      const firstNameLabel = intl.formatMessage({
        id: 'SignupForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName
      const lastNameLabel = intl.formatMessage({
        id: 'SignupForm.lastNameLabel',
      });
      const lastNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled =
        invalid || submitInProgress || !(values?.password === values?.confirmPassword);

      const handleTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenTermsOfService();
        }
      };
      const handlePrivacyKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenPrivacyPolicy();
        }
      };

      const termsLink = (
        <span
          className={css.termsLink}
          onClick={onOpenTermsOfService}
          role="button"
          tabIndex="0"
          onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="SignupForm.termsLinkText" />
        </span>
      );
      
      const privacyLink = (
        <span
          className={css.termsLink}
          onClick={onOpenPrivacyPolicy}
          role="button"
          tabIndex="0"
          onKeyUp={handlePrivacyKeyUp}
        >
          <FormattedMessage id="SignupForm.privacyLinkText" />
        </span>
      ); 

      const IconPassword = isPasswordVisible ? (
        <div className={css.text} onClick={handlePasswordVisibilityToggle}>
          <FormattedMessage id="SignupForm.hide" />
        </div>
      ) : (
        <div className={css.text} onClick={handlePasswordVisibilityToggle}>
          <FormattedMessage id="SignupForm.show" />
        </div>
      );
      const IconConfirmPassword = isConfirmPasswordVisible ? (
        <div className={css.text} onClick={handleConfirmPasswordVisibilityToggle}>
          <FormattedMessage id="SignupForm.hide" />
        </div>
      ) : (
        <div className={css.text} onClick={handleConfirmPasswordVisibilityToggle}>
          <FormattedMessage id="SignupForm.show" />
        </div>
      );
      const handleFileChange = (event, form) => {
        const file = event.target.files[0];

        // Check if a file was selected
        if (!file) {
          return;
        }

        // Check if the file size is less than or equal to 15 MB (15 * 1024 * 1024 bytes)
        const maxSize = 15 * 1024 * 1024;
        if (file.size > maxSize) {
          alert('File size exceeds the maximum allowed (15 MB)');
          return;
        }

        // Check if the file type is one of the allowed formats (HEIC, PNG, JPG, JPEG)
        const allowedFormats = ['image/heic', 'image/png', 'image/jpeg', 'image/jpg'];
        if (!allowedFormats.includes(file.type)) {
          alert('Invalid file format. Please select a HEIC, PNG, JPG, or JPEG file.');
          return;
        }

        // Display the preview of the selected image
        const reader = new FileReader();
        reader.onload = e => {
          const imagePreviewUrl = e.target.result;
          form.change('profileImage', file);
          form.change('profileImagePreviewUrl', imagePreviewUrl);
        };
        reader.readAsDataURL(file);
        const tempId = `${file.name}_${Date.now()}`;
        onImageUpload({ id: tempId, file });
      };

      const handleImageClick = () => {
        // Trigger the file input when the image is clicked
        document.getElementById('logoInput').click();
      };

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <div className={css.photoUploadWrapper}>
              <label className={css.addPhotoLabel} htmlFor="logo">
                <FormattedMessage id="SignupForm.headingText" />
              </label>
              <input
                type="file"
                id="logoInput"
                name="profileImage"
                accept="image/*"
                onChange={event => handleFileChange(event, form)}
                style={{ display: 'none' }} // Hide the file input
              />
              <div
                id="logoPreview"
                className={css.uploadImageWrapper}
                onClick={handleImageClick} // Trigger file input when image is clicked
                style={{
                  cursor: 'pointer',
                  borderRadius: '50%', // Create a circular shape
                  overflow: 'hidden', // Hide overflow to maintain circular shape
                }}
              >
                {values?.profileImage && (
                  <img
                    src={values?.profileImagePreviewUrl}
                    alt="Logo Preview"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover', // Maintain aspect ratio and fill the circle
                    }}
                  />
                )}
              </div>
            </div>

            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.fname` : 'fname'}
                name="fname"
                autoComplete="given-name"
                label={firstNameLabel}
                placeholder={firstNamePlaceholder}
                validate={firstNameRequired}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lname` : 'lname'}
                name="lname"
                autoComplete="family-name"
                label={lastNameLabel}
                placeholder={lastNamePlaceholder}
                validate={lastNameRequired}
              />
            </div>
            <div className={classNames(css.name, css.emailInput)}>
              <FieldTextInput
                type="email"
                className={css.firstNameRoot}
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={emailLabel}
                placeholder={emailPlaceholder}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              <FieldTextInput
                // type="email"
                className={css.lastNameRoot}
                id={formId ? `${formId}.userName` : 'userName'}
                name="userName"
                autoComplete="email"
                label={userNameLabel}
                placeholder={userNamePlaceholder}
                validate={userNameRequired}
              />
            </div>
            {isUserNameAlreadyExit && !errorMessage ? (
              <p className={css.errorMessage}>
                <FormattedMessage id="SignupForm.errorMessage" />
              </p>
            ) : errorMessage || isUserNameAlreadyExit ? (
              <p className={css.errorMessage}>{errorMessage}</p>
            ) : null}
            <FieldTextInput
              className={css.password}
              type={isPasswordVisibleFunction(isPasswordVisible, 'password')}
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="new-password"
              label={passwordLabel}
              placeholder={passwordPlaceholder}
              validate={passwordValidators}
              inputIcon={IconPassword}
              inputWithImage={css.inputWithImage}
            />
            <FieldTextInput
              className={css.password}
              type={isConfirmPasswordVisibleFunction(isConfirmPasswordVisible, 'confirmPassword')}
              id={formId ? `${formId}.confirmPassword` : 'confirmPassword'}
              name="confirmPassword"
              autoComplete="new-password"
              label={confirmPasswordLabel}
              placeholder={passwordPlaceholder}
              validate={passwordValidators}
              inputIcon={IconConfirmPassword}
              inputWithImage={css.inputWithImage}
            />
          </div>

          <div className={css.bottomWrapper}>
            <p className={css.bottomWrapperText}>
              <span className={css.termsText}>
                <FormattedMessage
                  id="SignupForm.termsAndConditionsAcceptText"
                  values={{ termsLink ,privacyLink}}
                />
              </span>
            </p>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
